/* eslint-disable */

customElements.define(
  "headroom-nav",
  class extends HTMLElement {
    constructor() {
      super();
    }

    connectedCallback() {
      const headroom = new Headroom(this);
      headroom.init();
    }
  }
);

customElements.define(
  "flickity-slideshow",
  class extends HTMLElement {
    constructor() {
      super();
    }

    connectedCallback() {
      new Flickity(this.querySelector("div"), {
        autoPlay: true,
        pageDots: false,
        prevNextButtons: false,
        cellAlign: "left",
        fade: true,
        draggable: false,
        setGallerySize: false,
        pauseAutoPlayOnHover: false,
        imagesLoaded: true,
      });

      this.classList.add("flickity-slideshow--active");
    }
  }
);

customElements.define(
  "fade-in",
  class extends HTMLElement {
    constructor() {
      super();
    }

    connectedCallback() {
      const element = this;
      const duration = element.dataset.duration;

      const styles = `${
        duration ? `transition-duration: ${duration}s` : null
      };`;

      let observer = new IntersectionObserver(handler);
      observer.observe(element);

      function handler(entries, _) {
        for (let index = 0; index < entries.length; index++) {
          const entry = entries[index];

          if (entry.isIntersecting) {
            setTimeout(function () {
              element.setAttribute("style", styles);
              element.classList.add("fade-in--active");
            }, 250);
          }
        }
      }
    }
  }
);

document.addEventListener("DOMContentLoaded", () => {
  const navButtons = Array.from(document.querySelectorAll(".nav__button"));
  const navOverlay = document.querySelector(".nav-overlay");

  navButtons.forEach((element) => {
    element.addEventListener("click", () => {
      navOverlay.classList.toggle("nav-overlay--active");
    });
  });
});

$(document).ready(function () {
  setTimeout(() => {
    $(".project-section__text-inner")
      .stick_in_parent({
        offset_top: 60,
      })
      .on("sticky_kit:stick", function (e) {
        console.log("has stuck!", e.target);
      })
      .on("sticky_kit:unstick", function (e) {
        console.log("has unstuck!", e.target);
      });

    setTimeout(() => {
      $(document.body).trigger("sticky_kit:recalc");
    }, 0);
  }, 10);
});
